import React, {Component} from 'react';
import styled from '@emotion/styled';
import ClarizenLogo from '../../images/logos/clarizen.png';

class TopBar extends Component {



	render() {
		return(
			<Container>
				<LogoContainer>
					<Logo src={ClarizenLogo}/>
				</LogoContainer>
			</Container>
		);
	}


}



export default TopBar;

const Container = styled.div`
  background: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 6px 0 rgba(0,0,0,0.14);
    min-height: 80px;
    display: flex;
    flex: 1;
    height: 80px;
    width:100%;
    flex-direction: row;
    align-items: center;
    z-index: 10;
    border-radius: 6px;
`;

const LogoContainer = styled.div`
  
`;

const Logo = styled.img`
  background: url(${props => props.image}); 
`;