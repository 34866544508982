import React, {PureComponent} from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';


class Card extends PureComponent {

	render() {
		const {onClick} = this.props;

		return(
			<Container onClick={onClick}>
				{this.props.children}
			</Container>
		);
	}
}

export default Card;

Card.propTypes = {
	onClick: PropTypes.func,
	imageRenderer: PropTypes.func,
};

Card.defaultProps = {
	onClick: ()=>{}
};

const Container = styled.div`
  	background: #ffffff;
  	  &:hover {
  	  opacity: 0.8;
  	}
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 6px 0 rgba(0,0,0,0.14);
    min-height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    height: 200px;
    width: 300px;
    cursor: pointer;
`;
