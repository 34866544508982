import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import styled from '@emotion/styled';
import TopBar from '../common/TopBar';
import Card from '../common/Card';
import {ReactComponent as GoIcon} from '../../images/logos/go.svg';
import EagleLogo from '../../images/logos/eagle.png';
import {GET_CLARIZEN_GO_BASE_URL, GET_CLARIZEN_EAGLE_BASE_URL} from '../../config';


class Home extends Component {

	goRenderer = ()=>{
        const url = GET_CLARIZEN_GO_BASE_URL();

        return(
			<Card onClick={() => {this.redirectToTarget(url)}}>
				<GoIcon/>
			</Card>
		);
	};

	eagleRenderer = ()=>{
		const url = GET_CLARIZEN_EAGLE_BASE_URL();
		return(
			<Card onClick={() => {this.redirectToTarget(url)}}>
				<Logo src={EagleLogo}/>
			</Card>
		);
	};

    redirectToTarget = url => {
        window.location = url;
    };

	render() {
		return (
			<Container>
				<TopBar/>
				<CardsContainer>
					{this.goRenderer()}
					{this.eagleRenderer()}
				</CardsContainer>
			</Container>
		);
	}


}


export default Home;

const Container = styled.div`
  background-color: #3e4764;
  &:hover {
  }
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 80vw;
  height: calc(100vh - 80px);
	align-items: center;
	justify-content: space-evenly;

`;

const Logo = styled.img`
  background: url(${props => props.image}); 
`;


