import { invert } from 'lodash';

const envMap = {
  local: 'http://localhost:3000',
  development: 'https://portal.clarizen-ci.io',
  developmentEks: 'https://portal.clarizen-ci.io',
  staging: 'https://portalstg.clarizen.com',
  production: 'https://portal.clarizen.com',
};

const locationMap = invert(envMap);

const getCurrentBaseUrl = () =>
  window && window.location && window.location.host
    ? `${window.location.protocol}//${window.location.host}`
    : 'http://localhost:3000';

export const getEnv = () => {
  const myUrl = getCurrentBaseUrl();
  return locationMap[myUrl] ? locationMap[myUrl] : 'local';
};

export const GET_CLARIZEN_GO_BASE_URL = () => {
  const envs = {
    production: `https://go.clarizen.com`,
    staging: `https://gostg.clarizen.com`,
    development: `https://go.clarizen-ci.com`,
    developmentEks: `https://go.clarizen-ci.com`,
    local: `http://localhost:3000`,
  };

  return envs[getEnv()];
};

export const GET_CLARIZEN_EAGLE_BASE_URL = () => {
    const envs = {
        production: `https://eagle.clarizen.com`,
        staging: `https://eaglestg.clarizen.com`,
        development: `https://eagle.clarizen-ci.io`,
        developmentEks: `https://eagle.clarizen-ci.io`,
        local: `http://localhost:3000`,
    };

    return envs[getEnv()];
};

